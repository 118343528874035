@font-face {
  font-family: Montserrat;
  src: url("../../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family: Quicksand;
  src: url("../../assets/fonts/Quicksand/Quicksand-VariableFont_wght.ttf");
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b8ee;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00b8ee;
}

body {
  margin: 0;
  padding: 0;
}

.cursor {
  width: 20px;
  height: 20px;
  background-color: #a4c2f4;
  border-radius: 50%;
  position: absolute;
  z-index: 9999;
  display: none;
}

header {
  height: 13vh;
  width: 100%;
  top: 0;
  position: fixed;
  box-shadow: 1px 5px 5px grey;
  /* background-color: white; */
  background: linear-gradient(to right, #ffffff, #eef7fd);
  display: flex;
}

#headerLeft {
  height: 13vh;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#logo {
  height: 75%;
}

#headerRight {
  height: 13vh;
  /* background-color: #ffffff; */
}

nav ul li {
  font-size: 16px;
}

.navLink {
  color: #0d1b2b;
  text-decoration: none;
}

nav ul li.navItem:hover {
  transform: scale(1.2);
  cursor: pointer;
}

nav ul li button {
  background-color: #00b8ee;
  color: #ffffff;
  border: 1px solid #00b8ee;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  text-transform: uppercase;
}

nav ul li button:hover {
  background-color: white;
  color: #00b8ee;
  cursor: pointer;
}

#headerBtn {
  width: 30%;
  align-items: center;
  justify-content: center;
}

.headerIcon {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #005695;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

@media screen and (max-width: 1100px) {
  #logo {
    height: 50%;
  }
}

@media screen and (max-width: 768px) {
  #headerLeft {
    height: 13vh;
    width: 70%;
  }

  #headerRight {
    display: none;
    position: absolute;
    top: 13vh;
    height: 50vh;
    background: linear-gradient(to right, #ffffff, #eef7fd);
    width: 100vw;
  }

  nav ul {
    list-style-type: none;
    height: 45vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0%;
  }

  #headerBtn {
    display: flex;
  }

  .responsiveMenu {
    display: block !important;
  }
}

@media screen and (min-width: 769px) {
  #headerRight {
    width: 69%;
  }

  nav ul {
    height: 13vh;
    list-style-type: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  nav ul li {
    display: inline;
  }

  #headerBtn {
    display: none;
  }
}

main {
  padding: 13vh 0 0vh;
}

footer {
  background-color: #0d1b2b;
  color: #ffffff;
  bottom: 0;
  width: 100%;
}

.companyName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 50px;
  margin: 0;
  font-family: Quicksand;
  font-weight: bolder;
}

.privacyLinks {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.privacyLink {
  color: #ffffff;
  text-decoration: none;
}

.contactLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15vh;
}

.contactLinks button {
  background-color: #0d1b2b;
  color: #ffffff;
  border: 1px solid #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 0 0 5px 0;
  text-transform: capitalize;
}

.socialLinks {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: large;
}

.socialLink {
  color: #ffffff;
  text-decoration: none;
}

.sologan {
  font-size: 32px;
  font-family: Montserrat;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin: 0%;
}

.sologan b {
  font-weight: bolder;
}

.sologan span {
  color: #0d1b2b;
  text-shadow: -1px 1px 0 #ffffff, 1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
    -1px -1px 0 #ffffff;
}

/* Laptop/Large Screen */
@media screen and (min-width: 769px) {
  footer {
    display: flex;
    height: 30vh;
  }

  #footerLeft {
    width: 50%;
  }

  .companyName {
    height: 25vh;
  }

  .privacyLinks {
    width: 50%;
  }

  #footerRight {
    width: 50%;
  }

  .contactLinks button {
    width: 25%;
  }

  .sologan {
    height: 15vh;
    float: right;
    margin-right: 25px;
    right: 0;
  }
}

/* Mobile/Small Screen */
@media screen and (max-width: 768px) {
  footer {
    display: flex;
    flex-direction: column-reverse;
  }

  #footerLeft {
    width: 100%;
  }

  .companyName {
    padding: 15px 0;
  }

  .privacyLinks {
    padding: 10px 0;
  }

  #footerRight {
    width: 100%;
  }

  .contactLinks button {
    width: fit-content;
    margin-bottom: 15px;
  }

  .sologan {
    padding: 15px 0;
    justify-content: center;
    text-align: center;
  }
}
