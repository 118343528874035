@font-face {
  font-family: Montserrat;
  src: url(/static/media/Montserrat-VariableFont_wght.a3fcdff0.ttf);
}

@font-face {
  font-family: Quicksand;
  src: url(/static/media/Quicksand-VariableFont_wght.51b6fe99.ttf);
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b8ee;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00b8ee;
}

body {
  margin: 0;
  padding: 0;
}

.cursor {
  width: 20px;
  height: 20px;
  background-color: #a4c2f4;
  border-radius: 50%;
  position: absolute;
  z-index: 9999;
  display: none;
}

header {
  height: 13vh;
  width: 100%;
  top: 0;
  position: fixed;
  box-shadow: 1px 5px 5px grey;
  /* background-color: white; */
  background: linear-gradient(to right, #ffffff, #eef7fd);
  display: flex;
}

#headerLeft {
  height: 13vh;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#logo {
  height: 75%;
}

#headerRight {
  height: 13vh;
  /* background-color: #ffffff; */
}

nav ul li {
  font-size: 16px;
}

.navLink {
  color: #0d1b2b;
  text-decoration: none;
}

nav ul li.navItem:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  cursor: pointer;
}

nav ul li button {
  background-color: #00b8ee;
  color: #ffffff;
  border: 1px solid #00b8ee;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  text-transform: uppercase;
}

nav ul li button:hover {
  background-color: white;
  color: #00b8ee;
  cursor: pointer;
}

#headerBtn {
  width: 30%;
  align-items: center;
  justify-content: center;
}

.headerIcon {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #005695;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

@media screen and (max-width: 1100px) {
  #logo {
    height: 50%;
  }
}

@media screen and (max-width: 768px) {
  #headerLeft {
    height: 13vh;
    width: 70%;
  }

  #headerRight {
    display: none;
    position: absolute;
    top: 13vh;
    height: 50vh;
    background: linear-gradient(to right, #ffffff, #eef7fd);
    width: 100vw;
  }

  nav ul {
    list-style-type: none;
    height: 45vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0%;
  }

  #headerBtn {
    display: flex;
  }

  .responsiveMenu {
    display: block !important;
  }
}

@media screen and (min-width: 769px) {
  #headerRight {
    width: 69%;
  }

  nav ul {
    height: 13vh;
    list-style-type: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  nav ul li {
    display: inline;
  }

  #headerBtn {
    display: none;
  }
}

main {
  padding: 13vh 0 0vh;
}

footer {
  background-color: #0d1b2b;
  color: #ffffff;
  bottom: 0;
  width: 100%;
}

.companyName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 50px;
  margin: 0;
  font-family: Quicksand;
  font-weight: bolder;
}

.privacyLinks {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.privacyLink {
  color: #ffffff;
  text-decoration: none;
}

.contactLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15vh;
}

.contactLinks button {
  background-color: #0d1b2b;
  color: #ffffff;
  border: 1px solid #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 0 0 5px 0;
  text-transform: capitalize;
}

.socialLinks {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: large;
}

.socialLink {
  color: #ffffff;
  text-decoration: none;
}

.sologan {
  font-size: 32px;
  font-family: Montserrat;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin: 0%;
}

.sologan b {
  font-weight: bolder;
}

.sologan span {
  color: #0d1b2b;
  text-shadow: -1px 1px 0 #ffffff, 1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
    -1px -1px 0 #ffffff;
}

/* Laptop/Large Screen */
@media screen and (min-width: 769px) {
  footer {
    display: flex;
    height: 30vh;
  }

  #footerLeft {
    width: 50%;
  }

  .companyName {
    height: 25vh;
  }

  .privacyLinks {
    width: 50%;
  }

  #footerRight {
    width: 50%;
  }

  .contactLinks button {
    width: 25%;
  }

  .sologan {
    height: 15vh;
    float: right;
    margin-right: 25px;
    right: 0;
  }
}

/* Mobile/Small Screen */
@media screen and (max-width: 768px) {
  footer {
    display: flex;
    flex-direction: column-reverse;
  }

  #footerLeft {
    width: 100%;
  }

  .companyName {
    padding: 15px 0;
  }

  .privacyLinks {
    padding: 10px 0;
  }

  #footerRight {
    width: 100%;
  }

  .contactLinks button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 15px;
  }

  .sologan {
    padding: 15px 0;
    justify-content: center;
    text-align: center;
  }
}

@font-face {
  font-family: Montserrat;
  src: url(/static/media/Montserrat-VariableFont_wght.a3fcdff0.ttf);
}

.homeSec2 h3,
.homeSec3 h3,
.homeSec4 h3,
.homeSec5 h3,
.homeSec6 h3,
.homeSec7 h3,
.homeSec8 h3,
.homeSec9 h3 {
  text-transform: uppercase;
  font-family: Montserrat;
  color: #1b90be;
  font-weight: bolder;
  font-size: 25px;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17vh;
  background-color: #1b90be;
}

.homeSec2 h3,
.homeSec3 h3,
.homeSec4 h3 {
  color: #1b90be;
}

.homeSec4 h3,
.homeSec5 h3,
.homeSec6 h3,
.homeSec7 h3,
.homeSec8 h3,
.homeSec9 h3 {
  color: #000000;
}

button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #00b8ee;
  color: #ffffff;
  border: 1px solid #00b8ee;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  text-transform: uppercase;
}

button:hover {
  background-color: white;
  color: #00b8ee;
  cursor: pointer;
}

.tile {
  background-color: #ffffff;
  box-shadow: 1px 1px 10px grey;
}

.homeSec1 {
  width: 100%;
  display: flex;
  background-color: #eef7fd;
}

.homeSec1Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Montserrat;
}

.homeSec1Right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeSec2,
.homeSec3,
.homeSec4,
.homeSec5,
.homeSec6,
.homeSec7,
.homeSec8,
.homeSec9 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.homeSec4 {
  background-color: #eef7fd;
}

.homeSec2Tiles,
.homeSec3Tiles {
  width: 100%;
  display: flex;
}

.homeSec2Tiles .tile,
.homeSec3Tiles .tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homeSec2Tiles .tile h4,
.homeSec3Tiles .tile h4 {
  font-family: Montserrat;
  width: 60%;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
}

.homeSec2Tiles .tile p,
.homeSec3Tiles .tile p {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.homeSec4 p {
  width: 100%;
  text-align: center;
  font-weight: bolder;
  font-size: xx-large;
  color: #1b90be;
  font-family: Montserrat;
}

.homeSec5Div1 {
  width: 100%;
  display: flex;
  background-color: #00b8ee;
}

.homeSec5Div2 {
  width: 100%;
  display: flex;
  background-color: #1b90be;
}

.homeSec6 {
  margin-top: 50vh;
}

/* Laptop/Large Screen */
@media screen and (min-width: 769px) {
  .homeSec1 {
    height: 87vh;
  }

  .homeSec1Left {
    width: 50%;
  }

  .homeSec1Left h2 {
    margin: 0;
    padding: 5% 20%;
    font-size: 40px;
  }

  .homeSec1Left p {
    margin: 0;
    padding: 2% 0% 2% 20%;
    font-size: 20px;
  }

  .homeSec1Left button {
    margin: 2% 20%;
  }

  .homeSec1Right {
    width: 50%;
  }

  .homeSec1Right img {
    width: 80%;
  }

  .homeSec2,
  .homeSec3,
  .homeSec4,
  .homeSec5,
  .homeSec6,
  .homeSec7,
  .homeSec8,
  .homeSec9 {
    height: 87vh;
  }

  .homeSec2Tiles,
  .homeSec3Tiles {
    height: 70vh;
    justify-content: space-evenly;
  }

  .homeSec2Tiles .tile {
    width: 25%;
    height: 60vh;
  }

  .homeSec3Tiles .tile {
    margin-top: 10vh;
    width: 35%;
    height: 50vh;
  }

  .homeSec4 img {
    height: 60vh;
  }

  .homeSec5Div1 {
    height: 35vh;
    align-items: center;
  }

  .homeSec5Div2 {
    height: 35vh;
  }
}

/* Mobile/Small Screen */
@media screen and (max-width: 768px) {
  .homeSec1 {
    flex-direction: column-reverse;
  }

  .homeSec1Left {
    width: 100%;
    align-items: center;
  }

  .homeSec1Left h2 {
    width: 90%;
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 25px;
    text-align: center;
    font-stretch: ultra-expanded;
  }

  .homeSec1Left p {
    width: 90%;
    font-size: 16px;
    text-align: center;
    /* background-color: #00b8ee; */
  }

  .homeSec1Left button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 2% 0 5%;
    background-color: #00b8ee;
    color: #ffffff;
    border: 1px solid #00b8ee;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 8px;
    text-transform: uppercase;
  }

  .homeSec1Right {
    width: 100%;
  }

  .homeSec1Right img {
    width: 80%;
  }

  .homeSec2Tiles,
  .homeSec3Tiles {
    flex-direction: column;
  }

  .homeSec2Tiles .tile,
  .homeSec3Tiles .tile {
    width: 70%;
    margin: 2% 15%;
    padding: 5% 0;
  }

  .homeSec4 img {
    width: 90%;
    margin: 0 5%;
  }
}

