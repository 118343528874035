@font-face {
  font-family: Montserrat;
  src: url("../../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

.homeSec2 h3,
.homeSec3 h3,
.homeSec4 h3,
.homeSec5 h3,
.homeSec6 h3,
.homeSec7 h3,
.homeSec8 h3,
.homeSec9 h3 {
  text-transform: uppercase;
  font-family: Montserrat;
  color: #1b90be;
  font-weight: bolder;
  font-size: 25px;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17vh;
  background-color: #1b90be;
}

.homeSec2 h3,
.homeSec3 h3,
.homeSec4 h3 {
  color: #1b90be;
}

.homeSec4 h3,
.homeSec5 h3,
.homeSec6 h3,
.homeSec7 h3,
.homeSec8 h3,
.homeSec9 h3 {
  color: #000000;
}

button {
  width: fit-content;
  background-color: #00b8ee;
  color: #ffffff;
  border: 1px solid #00b8ee;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  text-transform: uppercase;
}

button:hover {
  background-color: white;
  color: #00b8ee;
  cursor: pointer;
}

.tile {
  background-color: #ffffff;
  box-shadow: 1px 1px 10px grey;
}

.homeSec1 {
  width: 100%;
  display: flex;
  background-color: #eef7fd;
}

.homeSec1Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Montserrat;
}

.homeSec1Right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeSec2,
.homeSec3,
.homeSec4,
.homeSec5,
.homeSec6,
.homeSec7,
.homeSec8,
.homeSec9 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.homeSec4 {
  background-color: #eef7fd;
}

.homeSec2Tiles,
.homeSec3Tiles {
  width: 100%;
  display: flex;
}

.homeSec2Tiles .tile,
.homeSec3Tiles .tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homeSec2Tiles .tile h4,
.homeSec3Tiles .tile h4 {
  font-family: Montserrat;
  width: 60%;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
}

.homeSec2Tiles .tile p,
.homeSec3Tiles .tile p {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.homeSec4 p {
  width: 100%;
  text-align: center;
  font-weight: bolder;
  font-size: xx-large;
  color: #1b90be;
  font-family: Montserrat;
}

.homeSec5Div1 {
  width: 100%;
  display: flex;
  background-color: #00b8ee;
}

.homeSec5Div2 {
  width: 100%;
  display: flex;
  background-color: #1b90be;
}

.homeSec6 {
  margin-top: 50vh;
}

/* Laptop/Large Screen */
@media screen and (min-width: 769px) {
  .homeSec1 {
    height: 87vh;
  }

  .homeSec1Left {
    width: 50%;
  }

  .homeSec1Left h2 {
    margin: 0;
    padding: 5% 20%;
    font-size: 40px;
  }

  .homeSec1Left p {
    margin: 0;
    padding: 2% 0% 2% 20%;
    font-size: 20px;
  }

  .homeSec1Left button {
    margin: 2% 20%;
  }

  .homeSec1Right {
    width: 50%;
  }

  .homeSec1Right img {
    width: 80%;
  }

  .homeSec2,
  .homeSec3,
  .homeSec4,
  .homeSec5,
  .homeSec6,
  .homeSec7,
  .homeSec8,
  .homeSec9 {
    height: 87vh;
  }

  .homeSec2Tiles,
  .homeSec3Tiles {
    height: 70vh;
    justify-content: space-evenly;
  }

  .homeSec2Tiles .tile {
    width: 25%;
    height: 60vh;
  }

  .homeSec3Tiles .tile {
    margin-top: 10vh;
    width: 35%;
    height: 50vh;
  }

  .homeSec4 img {
    height: 60vh;
  }

  .homeSec5Div1 {
    height: 35vh;
    align-items: center;
  }

  .homeSec5Div2 {
    height: 35vh;
  }
}

/* Mobile/Small Screen */
@media screen and (max-width: 768px) {
  .homeSec1 {
    flex-direction: column-reverse;
  }

  .homeSec1Left {
    width: 100%;
    align-items: center;
  }

  .homeSec1Left h2 {
    width: 90%;
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 25px;
    text-align: center;
    font-stretch: ultra-expanded;
  }

  .homeSec1Left p {
    width: 90%;
    font-size: 16px;
    text-align: center;
    /* background-color: #00b8ee; */
  }

  .homeSec1Left button {
    width: fit-content;
    margin: 2% 0 5%;
    background-color: #00b8ee;
    color: #ffffff;
    border: 1px solid #00b8ee;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 8px;
    text-transform: uppercase;
  }

  .homeSec1Right {
    width: 100%;
  }

  .homeSec1Right img {
    width: 80%;
  }

  .homeSec2Tiles,
  .homeSec3Tiles {
    flex-direction: column;
  }

  .homeSec2Tiles .tile,
  .homeSec3Tiles .tile {
    width: 70%;
    margin: 2% 15%;
    padding: 5% 0;
  }

  .homeSec4 img {
    width: 90%;
    margin: 0 5%;
  }
}
